var FEATURES = {

  navSearch: function() {
    //Show/hide nav search
    jQuery('#nav-search-button').click(function() {
      jQuery('#nav-search-form').fadeToggle( 'slow', 'linear' );
      if (jQuery('#nav-bg').hasClass('stickytop')){
        if (jQuery(window).scrollTop() >= 10){

        }else{
          jQuery('#nav-bg').fadeOut('slow', 'linear', function() {
            jQuery(this).removeClass('stickytop');
          });
        }
      }else{
        jQuery('#nav-bg').fadeIn('slow', 'linear', function() {
          jQuery(this).addClass('stickytop');
        });
      }
    });
  },

  stickyHeader: function() {
    //Sticky header
    jQuery(window).scroll(function() {
      if (jQuery(this).scrollTop() >= 10) {
          jQuery('#nav-bg').fadeIn('slow', 'linear', function() {
            jQuery(this).addClass('stickytop');
          });
      }
      else if (jQuery('#nav-search-form').is(':visible')){

      }else{
        jQuery('#nav-bg').fadeOut('slow', 'linear', function() {
          jQuery(this).removeClass('stickytop');
        });
      }
    });
  },

  galleryGrab: function(){
    //post gallery
    jQuery(document).ready(function() {
      var linkTag  = jQuery('.gallery-icon').find('a');
      var firstImg = jQuery('.gallery').find('a:first');
      var firstSrc = firstImg.attr('href');
      
      linkTag.click(function(event){
        event.preventDefault();
      });
      jQuery('.full-img').css('background-image', 'url('+ firstSrc +')');
      
      jQuery('.gallery-icon').click(function(){
        var newImg = jQuery(this).find('a:first');
        var newSrc = newImg.attr('href');
        jQuery('.full-img').css('background-image', 'url('+ newSrc +')');
      });

    });
  },

  emailTransfer: function(){
    jQuery('#pass-email').click( function(event){
      var email = jQuery('#email-addr').val();
      var newUrl = jQuery('#pass-email').attr('href');

      jQuery('#pass-email').attr('href', newUrl + '?email=' + email);

    });
  },
  emailCheck: function(){
    if (jQuery('#sub-email').val() == '') {
      jQuery('#sub-email').attr('placeholder', 'Eamil Address*');
    }
  },
  videoStart: function(){
    jQuery('.ug-button-videoplay').trigger('click');
    function nextSlide(){
      jQuery('.ug-arrow-right').trigger('click');
    }
    jQuery('#play').click(function(){
      jQuery("#play").prop("disabled", ture);
      jQuery('.ug-button-videoplay').trigger('click');
      setTimeout(nextSlide, 2000);
    });
  }
};

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        FEATURES.navSearch();
        FEATURES.stickyHeader();
        FEATURES.galleryGrab();
        FEATURES.emailTransfer();
        FEATURES.videoStart();
        // ParticleJS JSON
        var prtcljs = 
          {
            "particles": {
              "number": {
                "value": 100,
                "density": {
                  "enable": true,
                  "value_area": 800
                }
              },
              "color": {
                "value": "#a0a0a0"
              },
              "shape": {
                "type": "circle",
                "stroke": {
                  "width": 0,
                  "color": "#a0a0a0"
                },
                "polygon": {
                  "nb_sides": 5
                },
                "image": {
                  "src": "img/github.svg",
                  "width": 100,
                  "height": 0
                }
              },
              "opacity": {
                "value": 0.75,
                "random": false,
                "anim": {
                  "enable": false,
                  "speed": 1,
                  "opacity_min": 0.1,
                  "sync": false
                }
              },
              "size": {
                "value": 6,
                "random": true,
                "anim": {
                  "enable": false,
                  "speed": 40,
                  "size_min": 0.1,
                  "sync": false
                }
              },
              "line_linked": {
                "enable": true,
                "distance": 200,
                "color": "#a0a0a0",
                "opacity": 0.4,
                "width": 1
              },
              "move": {
                "enable": true,
                "speed": 1,
                "direction": "none",
                "random": false,
                "straight": false,
                "out_mode": "bounce",
                "bounce": false,
                "attract": {
                  "enable": false,
                  "rotateX": 600,
                  "rotateY": 1200
                }
              }
            },
            "interactivity": {
              "detect_on": "canvas",
              "events": {
                "onhover": {
                  "enable": true,
                  "mode": "grab"
                },
                "onclick": {
                  "enable": false,
                  "mode": "push"
                },
                "resize": true
              },
              "modes": {
                "grab": {
                  "distance": 400,
                  "line_linked": {
                    "opacity": 1
                  }
                },
                "bubble": {
                  "distance": 400,
                  "size": 40,
                  "duration": 2,
                  "opacity": 8,
                  "speed": 3
                },
                "repulse": {
                  "distance": 200,
                  "duration": 0.4
                },
                "push": {
                  "particles_nb": 4
                },
                "remove": {
                  "particles_nb": 2
                }
              }
            },
            "retina_detect": true
          };
        particlesJS("particle-home", prtcljs);
        particlesJS("particle-footer", prtcljs);
        // End ParticleJS JSON
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
     // Subscribe page, note the change from about-us to about_us.
    'subscribe': {
      init: function() {
        // JavaScript to be fired on the about us page
        FEATURES.emailCheck();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

